import { gql } from '@apollo/client';
import { noteGQL } from './queries';

export const CREATE_NOTE = gql`
  mutation noteCreateMutation($note:CreateNoteCommandInput) {
    noteCreate(note: $note) {
       ${noteGQL}
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation noteUpdateMutation($note:UpdateNoteCommandInput) {
    noteUpdate(note: $note) {
       ${noteGQL}
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation noteDeleteMutation($note:DeleteNoteCommandInput) {
    noteDelete(note: $note) {
       ${noteGQL}
    }
  }
`;



