import React from 'react';
import { Badge, Button, Col, FormControl, ListGroup, Row, Spinner } from 'react-bootstrap';
import { MemberEventProps } from './MemberEventProps';
import { useMemberEvents } from './useMemberEvents';

const MemberEvents: React.FC<MemberEventProps> = (props) => {

    const { models, operations } = useMemberEvents({ ...props });
    const { events, keywords, member, confirmDelete, loading } = models;
    const { setKeywords, setConfirmDelete, handleDeleteMemberEvent } = operations;

    return (
        <>
            <Row>
                <Col xs={"auto"} >
                    <FormControl
                        placeholder={"search..."}
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        className="mb-2"
                    />
                </Col>

            </Row>
            <Row>
                <Col>
                    {loading ? <Spinner /> :
                        <ListGroup className="mt-2" style={{ overflowY: 'auto' }}>
                            {events && events.map(evt => {
                                return (
                                    <ListGroup.Item key={evt.eventId} variant={new Date(evt.createdDate) > new Date() ? undefined : "secondary"}>
                                        <Row>
                                            <Col>
                                                {evt.name}{evt.cancelled === true && <Badge className="m-1" bg="danger">Cancelled</Badge>}
                                            </Col>
                                            <Col xs={"auto"} style={{ textAlign: 'right' }}>
                                                <Badge
                                                    style={evt.cancelled ? { opacity: 0.4 } : undefined}>
                                                    {new Date(evt.startDate).toLocaleDateString("en-gb")}
                                                </Badge>
                                            </Col>

                                            {confirmDelete === evt.eventId ?
                                                <Col xs={"auto"}>
                                                    <span className="text-danger">Are you sure?</span>
                                                    <Button size="sm" className="ms-2" variant="secondary" onClick={() => setConfirmDelete(undefined)}>
                                                        No
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        className="ms-2"
                                                        variant="danger"
                                                        onClick={() => handleDeleteMemberEvent(member.memberId, evt.eventId)}
                                                    >
                                                        Yes
                                                    </Button>
                                                </Col> :
                                                <Col xs={"auto"}>
                                                    <Button
                                                        size="sm"
                                                        variant="outline-danger"
                                                        onClick={() => setConfirmDelete(evt.eventId)}
                                                    >
                                                        <i className="bi bi-trash"></i>
                                                    </Button>
                                                </Col>
                                            }

                                        </Row>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    }
                </Col>
            </Row>

        </>

    );
};

export default MemberEvents;