import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import CommunicationPreferencesProps from './CommunicationPreferencesProps';
import { useCommunicationPreferences } from './useCommunicationPreferences';



const CommunicationPreferences: React.FC<CommunicationPreferencesProps> = (props) => {
    const { models, operations } = useCommunicationPreferences(props);

    const { show, formData } = models;
    const { handleOpen, handleClose, handleInputChangeEvent, onUpdate } = operations;

    return (
        <>
            <Button title="Update Preferences" variant="outline-primary" onClick={handleOpen}><i className="bi bi-gear"></i></Button>


            <Modal show={show} onHide={handleClose} >
                <Modal.Body >


                    <strong><p className="mb-4">Communication Preferences</p></strong>

                    <Row>
                        {formData
                            .map((preference, index) => (
                                <Form.Group as={Col} key={preference.name} sm={12} className="mb-3">
                                    <Form.Check
                                        type="switch"
                                        id={`switch-${preference.name}`}
                                        label={preference.name}
                                        checked={preference.enabled}
                                        onChange={() => handleInputChangeEvent(index)}
                                    />
                                </Form.Group>
                            ))}

                        <Form.Group as={Col} sm={12} className="mb-3">
                            <Form.Check
                                type="switch"
                                label={"Essential Communications"}
                                checked={true}
                                readOnly={true}
                                disabled={true}
                            />
                            <Form.Text>All members recieve essential communications. </Form.Text>
                        </Form.Group>
                    </Row>


                </Modal.Body>

                <Modal.Footer className="justify-content-center">
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => onUpdate().then(() => handleClose())}
                    >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CommunicationPreferences;
