import { useQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { GET_EMDR_TRAINING_LEVELS } from '../../../graphql/member';
import { EmdrTrainingLevel, Profession, ProfessionalRegistration, ProfessionItem, Region } from '../../../graphql/__generated__/graphql';
import { UpdateMemberDetailsModalProps } from './UpdateMemberDetailsModalProps';



export const useUpdateMemberDetails = ({ ...props }) => {

    var { member, onUpdate } = { ...props } as UpdateMemberDetailsModalProps;
    const { data } = useQuery(GET_EMDR_TRAINING_LEVELS);

    const [show, setShow] = useState(false);
    const [emdrLevel, setEmdrLevel] = useState<string>(member?.emdrTrainingLevel?.emdrTrainingLevelId ?? "");
    const [emdrDate, setEmdrDate] = useState<string>((member ? new Date(member.emdrTrainingLevelDateAchieved).toISOString() : new Date().toISOString()).slice(0, 10));
    const [caLevel, setCaLevel] = useState<string>(member?.childTrainingLevel ?? "");
    const [caDate, setCaDate] = useState<string>((member && member.childTrainingLevelDateAchieved) ? new Date(member.childTrainingLevelDateAchieved).toISOString() : "");
    const [languages, setLanguages] = useState<string>(member?.languages ?? "");
    const [region, setRegion] = useState<Region | undefined>(member?.region ?? undefined)
    const [professions, setProfessions] = useState<Profession[]>(member?.professions ?? []);
    const [registrations, setRegistrations] = useState<ProfessionalRegistration[]>(member?.professionalRegistrations ?? [])

    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSave = () => {
        setLoading(true);
        let updated = cloneDeep(member);
        if (updated) {

            updated.emdrTrainingLevelDateAchieved = new Date(emdrDate);
            updated.emdrTrainingLevel = data.emdrTrainingLevels.filter((lvl: EmdrTrainingLevel) => lvl.emdrTrainingLevelId === emdrLevel)[0];
            updated.childTrainingLevelDateAchieved = caDate.length > 0 ? new Date(caDate) : undefined;
            updated.childTrainingLevel = caLevel;
            updated.languages = languages;
            updated.region = region;
            updated.professions = professions;
            updated.professionalRegistrations = registrations;


            onUpdate(updated).then(() => {
                handleClose();
            }).catch((error) => {
            }).finally(() => setLoading(false));
        }
    };

    return {
        models: {
            emdrLevel, emdrDate, caLevel, caDate, languages, region, registrations, professions, show, loading
        },
        operations: {
            handleShow, handleSave, handleClose, setEmdrLevel, setEmdrDate, setCaLevel, setCaDate, setLanguages, setRegion, setRegistrations, setProfessions
        }
    };
};
