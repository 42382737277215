import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { Profession, ProfessionalRegistration, Region } from '../../../graphql/__generated__/graphql';
import CoreProfessionSelect from '../../Form/CoreProfessionSelect/CoreProfessionSelect';
import ProfessionalRegistrationSelect from '../../Form/ProfessionalRegistrationSelect/ProfessionalRegistrationSelect';
import RegionSelect from '../../Form/RegionSelect/RegionSelect';
import ChildLevelSelect from './ChildLevelSelect';
import EMDRLevelSelect from './EMDRLevelSelect/EMDRLevelSelect';
import { UpdateMemberDetailsModalProps } from './UpdateMemberDetailsModalProps';
import { useUpdateMemberDetails } from './useUpdateMemberDetails';


const UpdateMemberDetailsModal: React.FC<UpdateMemberDetailsModalProps> = ({ ...props }) => {

    const { member } = { ...props } as UpdateMemberDetailsModalProps;
    const { models, operations } = useUpdateMemberDetails({ ...props });
    const { caLevel, caDate, emdrLevel, emdrDate, languages, professions, region, registrations, show, loading } = models;
    const { setEmdrLevel, setEmdrDate, setCaLevel, setCaDate, setLanguages, setRegion, setProfessions, setRegistrations, handleShow, handleClose, handleSave } = operations;

    const [key, setKey] = useState<string>("region");

    return (
        member ?
            <>
                <Button
                    size="sm"
                    variant="outline-primary"
                    className="mb-2 me-2"
                    onClick={handleShow}
                >
                    <i className="bi bi-pencil me-1"></i>
                    Change
                </Button>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{member.firstname + '\'s Details'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-4">
                            <Col>
                                <Form.Group>
                                    <Form.Label>EMDR Training Level</Form.Label>
                                    <EMDRLevelSelect
                                        name="emdrLevel"
                                        onChange={(e) => setEmdrLevel(e.target.value)}
                                        value={emdrLevel}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Date Achieved</Form.Label>
                                    <Form.Control
                                        name="emdrLevelDate"
                                        type="date"
                                        required
                                        value={emdrDate?.toString() ?? ""}
                                        onChange={(e) => setEmdrDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Child Training Level</Form.Label>
                                    <ChildLevelSelect
                                        name="childLevel"
                                        onChange={(e) => setCaLevel(e.target.value)}
                                        value={caLevel}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Date Achieved</Form.Label>
                                    <Form.Control
                                        name="childLevelDate"
                                        type="date"
                                        value={caDate ?? ""}
                                        onChange={(e) => setCaDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <Form.Label>Languages</Form.Label>

                                <Form.Control
                                    as={"textarea"}
                                    value={languages}
                                    onChange={(e) => setLanguages(e.target.value)}
                                />

                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>


                            </Col>
                        </Row>




                        <Row className="mb-4">
                            <Col>
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k || 'region')} // Handles switching tabs
                                    className="mb-3"
                                >
                                    <Tab eventKey="region" title="Region">

                                        <RegionSelect
                                            name="region"
                                            value={region?.regionId ?? ""}
                                            onChange={(value) => setRegion(value as Region)}
                                        />
                                    </Tab>
                                    <Tab eventKey="profession" title="Profession">
                                        <Row className="mb-4">
                                            <CoreProfessionSelect
                                                name="professions"
                                                value={professions}
                                                onChange={(value) => setProfessions(value as Profession[])}
                                            />
                                        </Row>
                                    </Tab>

                                    <Tab eventKey="professionalRegistration" title="Professional Registration">
                                        <Row className="mb-4">
                                            <ProfessionalRegistrationSelect
                                                name="professionalRegistrations"
                                                value={registrations}
                                                onChange={(value) => setRegistrations(value as ProfessionalRegistration[])}
                                            />
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSave}
                            disabled={loading}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            :
            <Button variant="outline-primary" disabled >
                Change
            </Button >);
};

export default UpdateMemberDetailsModal;