import React from 'react';
import { Badge, Button, ButtonGroup, Card, CardBody, Col, FormControl, FormSelect, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BroadcastRecipientsModal from '../BroadcastRecipientsModal';
import BroadcastsLineChart from '../BroadcastsChart';
import CreateBroadcastModal from '../CreateBroadcastModal';
import { useBroadcasts } from './useBroadcasts';


const Broadcasts: React.FC = () => {

    const { models, operations } = useBroadcasts();
    const { loading, broadcasts, expired, keywords } = models;
    const { setExpired, setKeywords, nextPage, previousPage, reload } = operations;

    const calculatePercentage = (value: number, total: number): string => {
        if (total === 0) {
            return '0%';
        }

        const percentage = (value / total) * 100;
        return `${percentage.toFixed(0)}%`;
    }

    const navigate = useNavigate();

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody>
                <Row>
                    <Col style={{ textAlign: 'left' }} >
                        <h3>Broadcasts</h3>
                    </Col>
                    <Col xs={"auto"}>
                        <FormControl
                            placeholder={"search..."}
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                            className="mb-2"
                        />
                    </Col>
                    <Col xs={"auto"}>
                        <CreateBroadcastModal
                            onCreate={() => {
                                return new Promise((resolve) => {
                                    resolve(reload());
                                })
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="chart-container" style={{ position: 'relative', width: '100%' }} >
                            <BroadcastsLineChart />
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'left' }} >
                        <h6 className="mt-2">90 Day Broadcast Activity</h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {loading ? <Spinner />
                            : <Table>
                                <thead><tr><th className="text-start">Subject</th> <th className="text-start">Type</th><th className="text-start">Sent Date</th><th>Recipients</th><th>Sent</th></tr></thead>
                                <tbody>
                                    {broadcasts && broadcasts.map((broadcast) => {
                                        return (
                                            <tr key={broadcast.broadcastId}>
                                                <td className="text-start">{broadcast.subject}</td>
                                                <td className="text-start">{broadcast.type}</td>
                                                <td className="text-start">{broadcast.sentDate ? new Date(broadcast.sentDate).toLocaleDateString("en-gb") :
                                                    <CreateBroadcastModal
                                                        onCreate={() => {
                                                            return new Promise((resolve) => {
                                                                resolve(reload());
                                                            })
                                                        }}
                                                        draftBroadcast={broadcast}
                                                    /> }</td>
                                                <td className="text-start">
                                                    <BroadcastRecipientsModal broadcast={broadcast} />
                                                </td>
                                                <td>
                                                    {calculatePercentage(broadcast.recipients
                                                        .filter(x => x.sent === true).length, broadcast.recipients.length)}
                                                </td>
                                                {/*<td>*/}
                                                {/*    {calculatePercentage(broadcast.recipients*/}
                                                {/*        .filter(x => x.failed === true).length, broadcast.recipients.length)}*/}
                                                {/*</td>*/}
                                                {/*<td>*/}
                                                {/*    {calculatePercentage(broadcast.recipients*/}
                                                {/*        .filter(x => x.readCount > 0).length, broadcast.recipients.length)}</td>*/}

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        }
                    </Col>
                </Row>
                <ButtonGroup className="m-2" >
                    <Button disabled={!previousPage} onClick={previousPage}>
                        Back
                    </Button>
                    <Button disabled={!nextPage} onClick={nextPage}>
                        Next
                    </Button>
                </ButtonGroup>

            </CardBody>
        </Card>
    );
};

export default Broadcasts;