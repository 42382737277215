import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Spinner, Table } from 'react-bootstrap';
import { Document, Maybe } from '../../../graphql/__generated__/graphql';
import useDocuments from '../../../hooks/useDocuments';
import UploadDocumentModal from '../UploadDocumentModal';
import { EventDocumentModalProps } from './EventDocumentModalProps';


const useEventDocuments = ({ ...props }) => {


    const { uploadFile, removeFile, error } = useDocuments();

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [name, setName] = useState<string>();
    const [filename, setFilename] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [isValid, setIsValid] = useState<boolean>(selectedFile !== null);


    const { onUpdate, event } = { ...props } as EventDocumentModalProps

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setIsValid(false);
        onUpdate();
        setSelectedFile(null);
        setName(undefined);
        setDescription(undefined);
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleSave = () => {
        handleUpload().then(() => {
            handleClose();
        })
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const files = event.target.files;
        if (files && files.length > 0) {
            setName(files[0].name);
            setFilename(files[0].name);

            console.log("file", files[0].name);

            setSelectedFile(files[0]);
            setIsValid(true)
        }
    };

    const handleUpload = () => {
        return new Promise(async (resolve) => {
            if (selectedFile) {
                await uploadFile({
                    eventId: event.eventId,
                    name: name ?? "",
                    description: description ?? "",
                    file: selectedFile,
                    fileName: filename ?? ""
                });

                setSelectedFile(null);
                resolve(true);
            }
        });
    };




    return {
        models: {
            name, description, show, error, isValid
        },
        operations: {
            handleShow, handleSave, handleClose, setName, setDescription, handleFileChange
        }
    };
};


const EventDocumentsModal: React.FC<EventDocumentModalProps> = ({ event, onUpdate }) => {

    const documents = useDocuments();

    const { models, operations } = useEventDocuments({ event, onUpdate });
    const { show } = models;
    const { handleClose, handleShow } = operations;
    const [loading, setLoading] = useState(true);

    const [deleteConfirm, setDeleteConfirm] = useState<string>();

    const handleUpdate = () => {
        setLoading(true);
        onUpdate();
    }

    useEffect(() => {
        if (event)
            setLoading(false);
    }, [event])

    return (
        <>
            <Button
                size="sm"
                variant={(event.documents?.length ?? 0) > 0 ? "secondary" : "outline-secondary"}
                onClick={handleShow}
                className="ms-2"
                title="Event Documents"
            >
                <i className="bi bi-file-earmark"></i>
            </Button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Supporting Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="mb-2" >
                        <Card.Body>

                            {!event.documents && <small>There are currently no supporting documents for this event</small>}

                            <Table style={{ display: event.documents ? undefined : "none" }}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th />
                                    </tr>
                                </thead>

                                {loading ?
                                    <tbody>
                                        <tr>
                                            <td colSpan={6} className="text-center">
                                                <small>
                                                    <Spinner />
                                                    <p>Getting event documents</p>
                                                </small>

                                            </td>
                                        </tr>
                                    </tbody> :
                                    <tbody>
                                        {event && event.documents?.map((document: Maybe<Document>) => {

                                            return (
                                                <tr key={document?.documentId} style={{ verticalAlign: 'middle' }}>
                                                    <td>{document?.name}</td>
                                                    <td><small
                                                        className="d-inline-block text-truncate"
                                                        style={{ maxWidth: '200px' }}
                                                        title={document?.description ?? ""}
                                                    >{document?.description}</small></td>


                                                    {
                                                        deleteConfirm === document?.documentId ? <td><p>Confirm delete?</p>

                                                            <Button
                                                                size="sm"
                                                                variant="outline-danger"
                                                                onClick={() => setDeleteConfirm(undefined)}
                                                            >
                                                                <i className="bi bi-x"></i>
                                                            </Button>

                                                            <Button
                                                                size="sm"
                                                                variant="outline-success"
                                                                onClick={() => documents.removeFile(document?.documentId, event.eventId).then(() => {
                                                                    handleUpdate();
                                                                })}
                                                                style={{ marginLeft: '1em' }}
                                                            >
                                                                <i className="bi bi-check"></i>
                                                            </Button>
                                                        </td> :
                                                            <td>
                                                                <Button
                                                                    size="sm"
                                                                    variant="outline-primary"
                                                                    onClick={() => documents.downloadFile(document?.documentId, event.eventId)}
                                                                >
                                                                    <i className="bi bi-download"></i>
                                                                </Button>
                                                                <Button
                                                                    size="sm"
                                                                    variant="outline-danger"
                                                                    onClick={() => setDeleteConfirm(document?.documentId)}
                                                                    style={{ marginLeft: '1em' }}
                                                                >
                                                                    <i className="bi bi-trash"></i>
                                                                </Button>
                                                            </td>
                                                    }
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                }
                            </Table>

                        </Card.Body>
                    </Card>
                    <div>
                        <UploadDocumentModal
                            eventId={event.eventId}
                            onUpdate={handleUpdate}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EventDocumentsModal;