import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_COMMUNICATIONPREFERENCES, UPDATE_MEMBERCOMMUNICATIONPREFERENCES } from '../../graphql/member';
import { MemberCommunicationPreferenceDto, MemberCommunicationPreferenceDtoInput, UpdateMemberCommunicationPreferencesCommandInput } from '../../graphql/__generated__/graphql';
import CommunicationPreferencesProps from './CommunicationPreferencesProps';

export const useCommunicationPreferences = ({ memberId }: CommunicationPreferencesProps) => {


    const { loading, error, data, refetch } = useQuery(GET_COMMUNICATIONPREFERENCES, {
        variables: { memberId },
    });

    const [updateMemberCommunicationPreferences] = useMutation(UPDATE_MEMBERCOMMUNICATIONPREFERENCES);


    const [show, setShow] = useState<boolean>(false);
    const [formData, setFormData] = useState<MemberCommunicationPreferenceDto[]>([]);

    useEffect(() => {
        if (data?.communicationPreferences) {

            var dt: MemberCommunicationPreferenceDto[] = ([...data.communicationPreferences] as MemberCommunicationPreferenceDto[] )
                .sort((a, b) => {
                    if ((a.name ?? "") < (b.name ?? ""))
                        return -1;
                    if ((a.name ?? "") > (b.name ?? ""))
                        return 1;
                    return 0;
                })

            setFormData(dt);

            console.log("DATADATA DATA", data.communicationPreferences, formData);

        }
    }, [data]);

    useEffect(() => {
        if (formData.length > 0)
            console.log("FORM DATA", formData);
    }, [formData])

    const handleOpen = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleInputChangeEvent = (index: number) => {
        setFormData((prev) => prev.map((preference, i) => i === index ? { ...preference, enabled: !preference.enabled } : preference
        )
        );
    };

    const onUpdate = () => {
        return new Promise((resolve, reject) => {

            if (error)
                reject(error);

            resolve(updateMemberCommunicationPreferences({
                variables: {
                    command: {
                        memberId: memberId,
                        communicationPreferences: formData.map(x => {
                            return {
                                enabled: x.enabled,
                                memberId: x.memberId,
                                name: x.name,
                                userId: x.userId
                            } as MemberCommunicationPreferenceDtoInput;
                        })
                    } as UpdateMemberCommunicationPreferencesCommandInput
                }
            }).then(() => {
                refetch({
                    variables: { memberId: memberId },
                });

                handleClose();
            }));


        });
    };

    return {
        models: { show, formData },
        operations: { handleOpen, handleClose, handleInputChangeEvent, onUpdate }
    };
};
