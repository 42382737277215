import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { UPDATE_MEMBER_DOCUMENT_APPROVAL } from '../graphql/member/mutations';
import { UpdateMemberDocumentCommandInput } from '../graphql/__generated__/graphql';
import { ApprovalRequest } from '../interfaces/ApprovalRequest';
import { DocumentUploadRequest } from '../interfaces/DocumentUploadRequest';
import { UploadResponse } from '../interfaces/UploadResponse';


const DOCUMENT_API = process.env.REACT_APP_DOCUMENTAPI_ENDPOINT;

const useDocuments = () => {

    const auth = useAuth();
    const [error, setError] = useState<string | null>(null);
    const [updateDocumentApproval] = useMutation(UPDATE_MEMBER_DOCUMENT_APPROVAL);

    const handleUpdateDocumentApproval = (memberId: string, documentId: string, approve: boolean, cpd: number) => {
        return new Promise((resolve, reject) => {

            if (error)
                reject(error);

            resolve(updateDocumentApproval({
                variables: {
                    memberDocument: {
                        approved: approve === true,
                        rejected: approve === false,
                        cpd: cpd,
                        documentId,
                        memberId

                    } as UpdateMemberDocumentCommandInput
                }
            }));
        });
    };

    const uploadFile = async (request: DocumentUploadRequest): Promise<UploadResponse | null> => {

        try {
            const formData = new FormData();
            formData.append('file', request.file);
            formData.append('filename', request.fileName);
            formData.append('name', request.name);
            formData.append('description', request.description);
            formData.append('cpd', request.cpd?.toString() ?? "0");
            formData.append('expires', request.expires?.toISOString() ?? "");
            formData.append('memberId', request.memberId ?? "")
            formData.append('eventId', request.eventId ?? "")

            const response = await fetch(`${DOCUMENT_API}/Upload`, {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    'Authorization': 'Bearer ' + auth.user?.access_token
                })
            });

            if (!response.ok) {
                throw new Error('File upload failed.');
            }

            const data: UploadResponse = await response.json();
            return data;

        } catch (error) {

            console.log("Error uploading file.", error);

            setError('Error uploading file.');
            return null;
        }
    };

    const downloadFile = async (documentId: string, eventId?: string): Promise<void> => {
        try {

            const endpoint = eventId ? `${DOCUMENT_API}/Event/${eventId}/Download/${documentId}` : `${DOCUMENT_API}/Member/Download/${documentId}`

            const response = await fetch(endpoint, {
                headers: new Headers({
                    'Authorization': 'Bearer ' + auth.user?.access_token
                })
            });

            if (!response.ok) {
                throw new Error('File download failed.');
            }

            let reg = /filename=(.*);/ig;
            let s = response.headers.get("Content-Disposition");
            let filename = s?.match(reg)?.[0].replace("filename=", "")
                .replaceAll('"', "")
                .replaceAll(";", "");

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = filename ?? "download-file";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            setError('Error downloading file.');
        }
    };

    const removeFile = async (documentId: string, eventId: string): Promise<void> => {

        const response = await fetch(`${DOCUMENT_API}/Event/${eventId}/Remove/${documentId}`, {
            method: 'DELETE',
            headers: new Headers({
                'Authorization': 'Bearer ' + auth.user?.access_token
            })
        });

        if (!response.ok) {
            throw new Error('File remove failed.');
        }
    }

    const approve = async (request: ApprovalRequest): Promise<unknown> => {
        return handleUpdateDocumentApproval(request.memberId, request.documentId, true, request.cpd)
    }
    const reject = async (request: ApprovalRequest): Promise<unknown> => {
        return handleUpdateDocumentApproval(request.memberId, request.documentId, false, request.cpd)
    }

    return {
        uploadFile,
        downloadFile,
        removeFile,
        approve,
        reject,
        error,
    };
};

export default useDocuments;
