import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CREATE_BROADCAST } from '../../graphql/broadcast/mutations';
import { CreateBroadcastCommandInput, MemberDto } from '../../graphql/__generated__/graphql';
import { useEvents } from '../../hooks/useEvents';
import { useSegments } from '../../hooks/useSegments';
import { useMembers } from '../Members/useMembers';
import { BroadcastRecipientsModalProps } from './BroadcastRecipientsModalProps';



export const useBroadcastRecipients = ({ ...props }) => {

    var { broadcast } = { ...props } as BroadcastRecipientsModalProps;

    const [show, setShow] = useState(false);


    const [emailAddresses, setEmailAddresses] = useState<string[]>([]);

    const handleClose = () => {

        setShow(false);
    };

    const handleShow = () => setShow(true);


    useEffect(() => {

        if (broadcast.recipients)
            setEmailAddresses(broadcast.recipients.map(x => x.emailAddress))

    }, [broadcast])


    return {
        models: {
            emailAddresses, show
        },
        operations: {
            handleShow, handleClose
        }
    };
};
