import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_MEMBER_USERNAME } from '../../../graphql/member';

export const useUsernameBadge = ({ ...props }) => {

    const { userId } = props;
    const [username, setUsername] = useState<string>();

    const [inProgress, setInProgress] = useState(true);

    const { data, error, loading, refetch } = useQuery(GET_MEMBER_USERNAME, {
        variables: { userId }
    });

    useEffect(() => {
        if (!loading && !error) {
            setUsername(data.username);
            setInProgress(false);
        }
    }, [data]);

    return {
        models: {
            username, inProgress
        },
        operations: {


        }
    };
};
