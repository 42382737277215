import React from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { useUsernameBadge } from './useUsernameBadge';


const UsernameBadge: React.FC<{ userId: string }> = ({ userId }) => {

    const { models } = useUsernameBadge({ userId: userId ?? "" });
    const { username, inProgress } = models;



    return (

        <small title="username" style={{
            position: 'absolute'
        }}>
            <Alert variant="secondary" style={{ padding: 2 }}>
                {inProgress ? <>fetching username... <Spinner size="sm" /></> : <><i className="bi bi-person-lock"></i> {username}</>}
            </Alert>



        </small>


    );
};

export default UsernameBadge;