import React, { useEffect, useRef } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { UpdateNoteModalProps } from './UpdateNoteModalProps';
import { useUpdateNote } from './useUpdateNote';


const UpdateNoteModal: React.FC<UpdateNoteModalProps> = ({ ...props }) => {

    const { models, operations } = useUpdateNote({ ...props });
    const { note, error, show, confirmDelete, isValid } = models;
    const { handleShow, handleConfirmDelete, handleClose, handleSave, handleChange } = operations;

    const inputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (show === true)
            inputRef.current?.focus();
    }, [show])

    return (
        <>
            <Button
                size="sm"
                variant="outline-primary"
                onClick={handleShow}
            >
                <i className="bi bi-pencil"></i>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group>
                        <Form.Control
                            ref={inputRef}
                            type="textarea"
                            as={"textarea"}
                            rows={4}
                            required
                            placeholder="Enter note details"
                            value={note}
                            onChange={handleChange}
                        />
                        <Form.Text><b> Members cannot see notes:</b> they are for administrative purposes only.</Form.Text>
                    </Form.Group>

                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </Modal.Body>
                <Modal.Footer>
                    {confirmDelete ? <>
                        <p className="text-danger">Are you sure?</p>
                        <Button variant="secondary" onClick={handleConfirmDelete}>
                            No
                        </Button>

                        <Button
                            variant="danger"
                            onClick={() => handleSave(true)}
                        >
                            Yes
                        </Button>
                    </> : <>
                        <Button
                            variant="danger"
                            onClick={() => handleConfirmDelete()}
                            className="mr-4"
                        >
                            Delete Note
                        </Button>

                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>

                        <Button
                            variant="primary"
                            onClick={() => handleSave(false)}
                            disabled={note.length < 1}
                        >
                            Update
                        </Button>
                    </>}


                </Modal.Footer>
            </Modal>
        </>);
};

export default UpdateNoteModal;