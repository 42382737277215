import { useEffect, useState } from 'react';
import { ProfessionalRegistrationItem } from '../../../graphql/__generated__/graphql';

export const useProfessionalRegistrations = ({ data }: any) => {

    const [professionalRegistrations, setProfessionalRegistrations] = useState<ProfessionalRegistrationItem[]>();

    useEffect(() => {
        if (data?.professionalRegistrations && !professionalRegistrations) {
            handleLoadProfessionalRegistrations();
        }
    }, [data]);

    const handleLoadProfessionalRegistrations = async (): Promise<void> => {
        try {
            setProfessionalRegistrations(data.professionalRegistrations.map((x: ProfessionalRegistrationItem) => {
                return {
                    professionalRegistrationId: x.professionalRegistrationId,
                    name: x.name
                }
            }) as ProfessionalRegistrationItem[]);
        } catch (error) {
            console.error('Error processing professionalRegistrations:', error);
        }
    }
    return {
        models: { professionalRegistrations },
        operations: { handleLoadProfessionalRegistrations }
    };

};
